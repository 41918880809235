import React from "react";
import { useRef } from 'react';
import './contact.css'
import { AiOutlineMail } from 'react-icons/ai'
import { RiMessengerLine } from 'react-icons/ri'
import { AiOutlineWhatsApp } from 'react-icons/ai'
import emailjs from 'emailjs-com';

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_vxw8tul', 'template_y336xfj', form.current, 'dVDGOwvQ7EKShjhjz')
        .then(function(response) {
            alert("Votre message a été bien envoyé!",response);
         }, function(error) {
            alert('FAILED...', error);
         });
        e.target.reset()
      };

    return (
        <section id="contact">
            <h5> Contact</h5>
            <h2> Contactez Moi</h2>
            <div className="container contact__container">
                <div className="contact__options">
                    <article className="contact__option">
                        <AiOutlineMail className="contact__option-icon" />
                        <h4>Email</h4>
                        <h5>khaled.bribri@laposte.net</h5>
                        <a href='mailto:khaled.bribri@laposte.net' target="_blank">Envoyer moi un message</a>
                    </article>
                    <article className="contact__option">
                        <RiMessengerLine className="contact__option-icon" />
                        <h4>Messenger</h4>
                        <h5>Khaled BRIBRI</h5>
                        <a href='https://m.me/khaledbribri67200' target="_blank">Envoyer moi un message</a>
                    </article>
                    <article className="contact__option">
                        <AiOutlineWhatsApp className="contact__option-icon" />
                        <h4>What'up</h4>
                        <h5>Portable</h5>
                        <a href='https://wa.me/+33782476599' target="_blank">Envoyer moi un message</a>
                    </article>
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name="name" placeholder="votre nom" required></input>
                    <input type="text" name="email" placeholder="votre email" required></input>
                    <textarea name="message" rows="16" placeholder=" votre message" required></textarea>
                    <div className="btn-send">
                        <button type="submit" className="btn btn-primary " > Envoyer</button>
                    </div>
                </form>
            </div>
        </section>
    )
}

export default Contact;
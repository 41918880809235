import React from "react";
import './experience.css'
import { AiOutlineHtml5 } from 'react-icons/ai'
import { DiCss3 } from 'react-icons/di'
import { FaPhp } from 'react-icons/fa'
import { GrReactjs } from 'react-icons/gr'
import { DiSymfonyBadge } from 'react-icons/di'
import { DiJavascript1 } from 'react-icons/di'
import { DiPostgresql } from 'react-icons/di'
import { TbCSharp } from 'react-icons/tb'
import {CiSettings} from 'react-icons/ci'
const Experience = () => {
    return (
        <section id="experience">
            <h5>Mes Compétences</h5>
            <h2>Mon Expérience</h2>
            <div className="container experience__container">
                <div className="experience__web-developer">
                    <h3> Web developement</h3>
                    <div className="experience__content">
                        <article className="experience__details">
                            <AiOutlineHtml5 className="experience__details-icon" />
                            <div>
                                <h4>HTML</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <DiCss3 className="experience__details-icon" />
                            <div>
                                <h4>CSS</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <FaPhp className="experience__details-icon" />
                            <div>
                                <h4>PHP</h4>
                            </div>

                        </article>
                        <article className="experience__details">
                            <DiJavascript1 className="experience__details-icon" />
                            <div>
                                <h4>JAVASCRIPT</h4>
                            </div>

                        </article>
                        <article className="experience__details">
                            <GrReactjs className="experience__details-icon" />
                            <div>
                                <h4>REACT</h4>
                            </div>

                        </article>
                        <article className="experience__details">
                            <DiSymfonyBadge className="experience__details-icon" />
                            <div>
                                <h4>SYMFONY</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <CiSettings className="experience__details-icon" />
                            <div>
                                <h4>TWIG</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <DiPostgresql className="experience__details-icon" />
                            <div>
                                <h4>SQL</h4>
                            </div>

                        </article>
                    </div>
                </div>
                <div className="experience__vision-developper">
                    <h3> Vision developement</h3>
                    <div className="experience__content">
                        <article className="experience__details">
                            <TbCSharp className="experience__details-icon" />
                            <div>
                                <h4>C sharp</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <CiSettings className="experience__details-icon" />
                            <div>
                                <h4>IN-SIGHT EXPLORER</h4>
                            </div>
                        </article>
                        <article className="experience__details">
                            <CiSettings className="experience__details-icon" />
                            <div>
                                <h4>VISION PRO</h4>
                            </div>

                        </article>
                        <article className="experience__details">
                            <CiSettings className="experience__details-icon" />
                            <div>
                                <h4>VIDI</h4>
                            </div>

                        </article>
                        <article className="experience__details">
                            <CiSettings className="experience__details-icon" />
                            <div>
                                <h4>DATAMAN</h4>
                            </div>

                        </article>
                        <article className="experience__details">
                            <CiSettings className="experience__details-icon" />
                            <div>
                                <h4>DESIGNER</h4>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Experience
import React from "react";
import './header.css'
import CTA from './CTA'
import IMAGE from '../../assets/moi.png'
import HeaderSocial from "./headerSocial";
import {CgScrollV} from 'react-icons/cg';

const Header = () => {
    return(
        <header>
            <section id ="home" className="container header__container">
                <h5>Bonjour Je suis</h5>
                <h1>Khaled BRIBRI</h1>
                <h5 className="text-light">Développeur Web & Développeur Vision</h5>
                <CTA />
                <HeaderSocial />
                <a href="#contact" ><CgScrollV className="scroll__down" /></a>
                <div className="me">
                    <img className="img-header" src={IMAGE} alt="me"/>
                </div>
            </section>
        </header>
    ) 
}

export default Header
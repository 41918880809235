import React from 'react'
import './footer.css'
import {AiOutlineFacebook} from 'react-icons/ai'
import {AiFillGithub} from 'react-icons/ai'
import {AiOutlineLinkedin} from 'react-icons/ai'
import LOGO from './../../assets/logo.png'
export const Footer = () => {
  return (
    <footer>
        <a href="#" className='footer__logo'><img className='footer__img' src={LOGO} alt="logo"/></a>
        <ul className='permalinks'>
            <li><a href='#home'>Accueil</a></li>
            <li><a href='#about'>A propos</a></li>
            <li><a href='#experience'>Expérience</a></li>
            <li><a href='#contact'>Contact</a></li>
        </ul>
        <div className='footer__socials'>
            <a href='https://www.facebook.com/profile.php?id=100006689353195'><AiOutlineFacebook/></a>
            <a href='https://github.com/Khaled-Bribri'><AiFillGithub/></a>
            <a href='https://www.linkedin.com/in/khaled-bribri-38b9721b6'><AiOutlineLinkedin /></a>
        </div>
        <div className='copyright'>
            <small>Tous droits réservés © Khaled BRIBRI / 2022</small>
        </div>
    </footer>
  )
}
export default Footer


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Header from './Components/header/header';
import Nav from './Components/nav/nav';
import About from './Components/about/about';
import Experience from './Components/experience/experience';
import Contact from './Components/contact/contact';
import Footer from './Components/footer/footer';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <Header/>
    <Nav/>
    <About/>
    <Experience/>
    <Contact/>
    <Footer/>
  </React.StrictMode>
);
reportWebVitals();

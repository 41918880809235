import React from "react";
import './about.css'
import Image from '../../assets/moi.jpg'
import { FiAward } from 'react-icons/fi'
import {AiOutlineFolder} from 'react-icons/ai'

const About = () => {
    return (
        <section id="about">
            <h5>Faisons Connaissance</h5>
            <h2>A propos</h2>
            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img className="image-about" src={Image} alt="me" />
                    </div>
                    <div className="about__content">
                        <div className="about__cards">
                            <div className="about__cards">
                                <article className="about__card">
                                    <FiAward className="about_icons" />
                                    <h5>Développeur Web</h5>
                                    <small>2 ans d'expérience en tant que développeur web</small>
                                    <h5><AiOutlineFolder className="about_icons" /> Projets</h5>
                                    <small>
                                        <div>
                                            <a href ='https://camo-emploi.fr/'>Camo site</a><br/>
                                            <a href ='https://impulsion-acp.fr/'>Impultion</a><br/>
                                            <a>Applications web en PHP|Symfony</a><br/>
                                            ...
                                        </div>
                                    </small>
                                </article>
                                <article className="about__card">
                                    <FiAward className="about_icons" />
                                    <h5>Développeur Vision</h5>
                                    <small>7 ans d'expérience en tant que développeur vision</small> <br/>
                                    <h5><AiOutlineFolder className="about_icons" /> Projets réalisés</h5>
                                    <small>
                                        <div>
                                            <a href ='https://www.dolce-gusto.fr'>Capsules Checking DOLCE GUSTO </a><br/>
                                            <a href ='https://fr.special-t.com/fr/capsules-the.html'>Capsules Checking SPECIAL T </a><br/>
                                            <a href ='https://www.renaultgroup.com/groupe/implantations/usine-cleon/'>Localisation Culasse</a><br/>
                                            <a href ='https://www.tabacstop.be/nouvelles/iqos-la-nouvelle-cigarette-lectronique-de-philip-morris'>Cigarette Electronique IQOS Checking</a><br/>
                                            ...
                                        </div>
                                    </small>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <p>
                    Bonjour,<br></br>
                    Je m'appelle Khaled Bribri, j'ai 33 ans. Après avoir obtenu une licence professionnelle en électronique embarquée, j'ai travaillé pendant 7 ans en tant que développeur vision. Ce métier consistait à développer des systèmes de contrôle par caméra que j'ai eu l'occasion d'installer un peu partout dans le monde (Europe, Brésil, Vietnam).
                    Cependant, en raison de mes contraintes personnelles, j'ai décidé de changer de métier et de me former en développement web. Après une formation intensive de 5 mois, j'ai effectué un stage de 4 mois en tant que développeur web PHP/Symfony chez Thinkfab. Durant cette expérience, j'ai travaillé sur des logiciels pour différents domaines tels que la santé, la restauration et le travail temporaire.
                    <br></br>
                    A la fin de mon stage, j'ai intégré la société Flam SA en tant que responsable développement logiciel. Mon rôle consiste à maintenir l'application ERP de l'entreprise et développer de nouvelles fonctionnalités en PHP pur
                    <br></br>
                    Je suis très attaché à la qualité et à la maintenance du code, et j'applique les meilleures pratiques de développement pour garantir des solutions évolutives, fiables et durables.
                    Si vous cherchez un développeur web passionné et compétent pour votre prochain projet, n'hésitez pas à me contacter.
                    </p>
                    <div className="btn-talk">
                        <a href='#contact' className="btn btn-primary">Contactez-moi</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;
import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'

const headerSocial = () => {
  return (
    <div className='header__socials'>
       <a href="https://www.linkedin.com/in/khaled-bribri-38b9721b6" target='_blank'><BsLinkedin/></a>
       <a href="https://github.com/Khaled-Bribri" target='_blank'><FaGithub/></a>
    </div>
  )
}

export default headerSocial